<template>
  <div>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{ invalid }"
      >
        <b-form
          novalidate
          @submit.prevent="sendNotification"
        >
          <b-row>

            <!-- Title input -->
            <b-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                vid="title-input"
                :name="$t('labels.title')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.title')"
                  label-for="title-input"
                >
                  <b-form-input
                    id="title-input"
                    v-model="form.title"
                    :placeholder="$t('messages.your_title_notification_here')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>

          <b-row>

            <!-- Body input -->
            <b-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                vid="body-area"
                :name="$t('labels.body')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.body')"
                  label-for="body-area"
                >
                  <b-form-textarea
                    id="body-area"
                    v-model="form.body"
                    rows="4"
                    :placeholder="$t('messages.your_body_notification_here')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>

          <b-row>

            <!-- Image input -->
            <b-col cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                vid="image"
                :name="$t('labels.image')"
                rules="required"
              >
                <ImagePreview
                  v-model="image"
                  :label="$t('labels.image')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>

          </b-row>

          <b-row>

            <!-- Priority Selection -->
            <b-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                vid="priority-select"
                :name="$t('labels.priority')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.priority')"
                  label-for="priority-select"
                >
                  <b-form-select
                    id="priority-select"
                    v-model="form.priority"
                    :options="priorityOptions"
                    :placeholder="$t('messages.select_priority')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>

          <b-row>

            <!-- Submit and Reset buttons -->
            <b-col cols="12 text-right">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>

          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import formMixin from '@/mixins/formMixin'

export default {
  components: {
    flatPickr,
  },
  mixins: [formMixin],
  data() {
    return {
      config: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
      },
      image: null,

      // Form data object
      form: {
        title: null,
        body: null,
        image: null,
        priority: null,  // New field for priority selection
      },

      // Options for priority selection dropdown
      priorityOptions: [
        { value: 'high', text: this.$t('labels.high') },
        { value: 'medium', text: this.$t('labels.medium') },
        { value: 'low', text: this.$t('labels.low') },
      ],
    }
  },

  watch: {
    image(val) {
      if (val) {
        this.form.image = val
      } else {
        this.form.image = 'delete'
      }
    },
  },

  methods: {
    sendNotification() {
      let route = `/center-notification`

      let $form = this.loadFormData()

      const formData = this.serializeForm($form)
      this.$store.dispatch('app/isLoading', true)
      this.$refs.form.validate()
        .then(result => {
          if (result) {
            this.axios.post(route, formData)
              .then(response => {
                this.$store.dispatch('app/isLoading', false)

                this.afterSubmitWithoutRedirect(response.data.message)
                setTimeout(() => {
                  location.reload();
                }, 1000);
              })
              .catch(error => {
                this.$store.dispatch('app/isLoading', false)
                this.$bvToast.toast(error.response.data.message, {
                  title: this.$t('Error'),
                  variant: 'danger',
                  solid: true,
                  autoHideDelay: 5000,
                  appendToast: true,
                })
                this.$refs.form.setErrors(error.response.data.errors)
              })
              .finally(() => {
                this.$store.dispatch('app/isLoading', false)
              })
          } else {
            this.$store.dispatch('app/isLoading', false)
            this.formErrors = this.$refs.form.errors
            this.$bvToast.toast(this.$t('messages.please_fill_all_the_required_fields'), {
              title: this.$t('Error'),
              variant: 'danger',
              solid: true,
              autoHideDelay: 5000,
              appendToast: true,
            })
          }
        })
        .finally(() => {
          this.$store.dispatch('app/isLoading', false)
        })
    },
  },
}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
